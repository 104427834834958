import * as React from "react";
import Seo from "../components/shared/Seo";

export default function NotFound() {
  return <>
    <main>
      <Seo title="404" description="Not Found"/>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </main>
  </>;
}
